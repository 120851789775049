import React from 'react';
import { ExtensionComponentProps } from '@sharefiledev/sharefile-appshell';
import { Spin } from 'antd';

const LazyBlockDevelopmentPage = React.lazy(() => import('./BlockDevelopmentPage'));
export const AsyncBlockDevelopmentPage: React.FC<
	ExtensionComponentProps<unknown>
> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyBlockDevelopmentPage {...props} />
	</React.Suspense>
);
