import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Skeleton, Spin } from 'antd';
import { BlockNames } from '../../block.types';

export function addBlocks(piral: PiletApi) {
	const LazyClientTaskListBlock = React.lazy(() => import('./ClientTaskListBlock'));
	piral.registerExtension(BlockNames.ClientTaskList, props => (
		<React.Suspense fallback={<Spin />}>
			<LazyClientTaskListBlock {...props} />
		</React.Suspense>
	));

	const LazyTaskListBlock = React.lazy(() => import('./TaskListBlock'));
	piral.registerExtension(BlockNames.TaskList, props => (
		<React.Suspense fallback={<Skeleton />}>
			<LazyTaskListBlock {...props} />
		</React.Suspense>
	));

	/**
	 * @todo
	 * Does this really belong in task aggregator? It has an explicit dependency on a projectRID.
	 */
	const LazyExportFilesBlock = React.lazy(() => import('./ExportFilesButtonBlock'));
	piral.registerExtension(BlockNames.SaveFilesToFolder, props => (
		<React.Suspense fallback={<Skeleton />}>
			<LazyExportFilesBlock {...props} />
		</React.Suspense>
	));

	const LazyAllFilesBlock = React.lazy(() => import('./AllFilesBlock'));
	piral.registerExtension(BlockNames.AllFiles, props => (
		<React.Suspense fallback={<Skeleton />}>
			<LazyAllFilesBlock {...props} />
		</React.Suspense>
	));
}
