/* tslint:disable */
/**
 * This file is autogenerated using @sharefiledev/solution-view-engine-tools.
 *
 * Run 'sve typegen <relative path to manifest file>'
 *
 * Types are generated according to specification in a file that conforms to the UI Manifest schema:
 * https://us-solutionviewengine.sharefiletest.io/ui-manifest-v2.json
 */
import type {
	PiletApi,
	PiralCustomExtensionSlotMap as ShellPiralCustomExtensionSlotMap,
} from '@sharefiledev/sharefile-appshell';
import type { BlockParams } from '@sharefiledev/solution-view-engine';

export interface ShellProps {
	piletApi: PiletApi;
}
/** Block parameters for urn:block:task-aggregator:client-task-list */

export interface ClientTaskListBlockParams {
	containerRID?: string;
	kind?: 'actionable' | 'nonActionable';
	/**
	 * Control the resource types for tasks to aggregate.
	 */
	taskResourceTypes?: (
		| 'requestlist'
		| 'requestlist_item'
		| 'inforeq'
		| 'signature_request'
		| 'datatable'
		| 'reviewapproval'
	)[];
	/**
	 * vertical context key will be used to identify which skin to be applied for localization. Default is undefined
	 */
	verticalContextKey?: 'accounting';
}
/** Block parameters for urn:block:task-aggregator:task-list */

export interface TaskListBlockParams {
	containerRID: string | string[];
	kind?: 'actionable' | 'nonActionable';
	hideHeader?: boolean;
	/**
	 * Control the resource types for tasks to aggregate.
	 */
	taskResourceTypes?: (
		| 'requestlist'
		| 'requestlist_item'
		| 'inforeq'
		| 'signature_request'
		| 'datatable'
		| 'reviewapproval'
	)[];
	/**
	 * Title string for the empty state
	 */
	emptyTitle?: string;
	/**
	 * Secondary message for the empty state
	 */
	emptySecondaryMessage?: string;
	readonly?: boolean;
	/**
	 * vertical context key will be used to identify which skin to be applied for localization. Default is undefined
	 */
	verticalContextKey?: 'accounting';
}
/** Zone parameters for urn:zone:task-aggregator:task-list:header-filters */

export interface TaskListHeaderFiltersZoneParams {}
/** Zone parameters for urn:zone:task-aggregator:task-list:header-actions */

export interface TaskListHeaderActionsZoneParams {}
/** Block parameters for urn:block:task-aggregator:all-files */

export interface AllFilesBlockParams {}
/** Zone parameters for urn:zone:task-aggregator:all-files:header-actions */

export interface AllFilesHeaderActionsZoneParams {}
/** Zone parameters for urn:zone:task-aggregator:all-files:file-table */

export interface AllFilesFileTableZoneParams {}
/** Block parameters for urn:block:task-aggregator:save-files-to-folder */

export interface SaveFilesToFolderBlockParams {
	flowRID: string;
	projectRID: string;
}

export namespace BlockNames {
	export const ClientTaskList = 'urn:block:task-aggregator:client-task-list' as const;
	export type ClientTaskList = 'urn:block:task-aggregator:client-task-list';
	export const TaskList = 'urn:block:task-aggregator:task-list' as const;
	export type TaskList = 'urn:block:task-aggregator:task-list';
	export const AllFiles = 'urn:block:task-aggregator:all-files' as const;
	export type AllFiles = 'urn:block:task-aggregator:all-files';
	export const SaveFilesToFolder =
		'urn:block:task-aggregator:save-files-to-folder' as const;
	export type SaveFilesToFolder = 'urn:block:task-aggregator:save-files-to-folder';
}

export namespace ZoneTypes {
	export const TaskListHeaderFilters =
		'urn:zone:task-aggregator:task-list:header-filters' as const;
	export type TaskListHeaderFilters = 'urn:zone:task-aggregator:task-list:header-filters';
	export const TaskListHeaderActions =
		'urn:zone:task-aggregator:task-list:header-actions' as const;
	export type TaskListHeaderActions = 'urn:zone:task-aggregator:task-list:header-actions';
	export const AllFilesHeaderActions =
		'urn:zone:task-aggregator:all-files:header-actions' as const;
	export type AllFilesHeaderActions = 'urn:zone:task-aggregator:all-files:header-actions';
	export const AllFilesFileTable =
		'urn:zone:task-aggregator:all-files:file-table' as const;
	export type AllFilesFileTable = 'urn:zone:task-aggregator:all-files:file-table';
}

export type ClientTaskListProps = ShellProps & BlockParams & ClientTaskListBlockParams;
export type TaskListProps = ShellProps & BlockParams & TaskListBlockParams;
export type AllFilesProps = ShellProps & BlockParams & AllFilesBlockParams;
export type SaveFilesToFolderProps = ShellProps &
	BlockParams &
	SaveFilesToFolderBlockParams;

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {
		['urn:block:task-aggregator:client-task-list']: BlockParams &
			ClientTaskListBlockParams;
		['urn:block:task-aggregator:task-list']: BlockParams & TaskListBlockParams;
		['urn:block:task-aggregator:all-files']: BlockParams & AllFilesBlockParams;
		['urn:block:task-aggregator:save-files-to-folder']: BlockParams &
			SaveFilesToFolderBlockParams;
	}
}

declare module '@sharefiledev/solution-view-engine' {
	interface BlockCustomExtensionSlotMap extends ShellPiralCustomExtensionSlotMap {}

	interface PiletExtensionApi extends PiletApi {}

	interface ViewEngineCustomZoneParametersMap {
		['urn:zone:task-aggregator:task-list:header-filters']: TaskListHeaderFiltersZoneParams;
		['urn:zone:task-aggregator:task-list:header-actions']: TaskListHeaderActionsZoneParams;
		['urn:zone:task-aggregator:all-files:header-actions']: AllFilesHeaderActionsZoneParams;
		['urn:zone:task-aggregator:all-files:file-table']: AllFilesFileTableZoneParams;
	}

	interface ViewEngineCustomZoneContractDataMap {
		['urn:zone:task-aggregator:task-list:header-filters']: never;
		['urn:zone:task-aggregator:task-list:header-actions']: never;
		['urn:zone:task-aggregator:all-files:header-actions']: never;
		['urn:zone:task-aggregator:all-files:file-table']: never;
	}
}
